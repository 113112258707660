<template>
  <div id="app">
    <el-config-provider :locale="locale">
      <router-view/>
    </el-config-provider>
  </div>
</template>
<script setup>
import { ref, inject, reactive, onMounted, computed } from 'vue'
import userInfoService from '@/utils/userinfoservice'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'

const language = ref(window.SITE_CONFIG.defaultLanguage) // 获取默认语言
const locale = computed(() => (language.value === 'CN' ? zhCn : en)) // 配置element的默认语言
const userInfoGlobal = ref(inject('$userInfoGlobal'))
const state = reactive({
  userInfoGlobal
})
onMounted(() => {
  // 浏览器窗口可见性监听: visibilityState=visible 可见；visibilityState=hidden 不可见
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      userInfoService.getUserData(state.userInfoGlobal)
      // console.log('用户信息', state.userInfoGlobal)
    }
  })
})
// import {getUserAssets} from './utils/userassetsinfoservice'
//   export default {
//     name: "App",
//     data() {
//       return {
//         onLine: navigator.onLine  //获取设备是否可以上网、连接网络
//       };
//     },
//     mounted() {
//       // 没登陆就清除本地所有localStorage缓存信息
//       if (!this.GLOBAL.isLogin) {
//         window.localStorage.clear();
//       }
//       // 监听网络
//       // 网络由异常到正常时触发
//       window.addEventListener('online', this.updateOnlineStatus)
//       // 网络由正常到异常触发
//       window.addEventListener('offline', this.updateOfflineStatus)

//       // 窗口关闭或刷新时候的操作
//       window.addEventListener('unload',this.unload() );
//     },
//     methods: {
//       unload() {
//         if (this.$userinfoservice.getToken() )
//         {
//           getUserAssets().then(res => {
//             this.$userinfoservice.getUserinfo(this);
//             this.$root.isChange = true
//             this.$root.isChange = false

//           });
//         }
//       },
//       updateOnlineStatus() {
//         this.onLine = true
//         this.$message({
//           message: '网络连接成功 !',
//           type: 'success'
//         });
//       },
//       updateOfflineStatus() {
//         this.onLine = false
//         this.$confirm('网络已断开连接，请检查本地网络设置', '', {
//           showCancelButton: false,
//           center: true,
//           confirmButtonText: '知道了',
//           type: 'warning'
//         }).then(() => {
//           location.reload();
//         })
//       },
//     }
//   };
</script>
<style>
#app {
  font-family: Source Han Sans CN, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  /* background: #f5faff !important; */
  background: #fff !important;
}
</style>
