import { createRouter, createWebHashHistory } from 'vue-router'

const routePC = [
  {
    path: '/',
    name: 'toolbox',
    component: () => import('@/views/layout/layoutMain.vue'),
    redirect: '/toolbox',
    children: [
      {
        path: '/toolbox',
        name: 'toolbox',
        component: () => import('@/views/homePage/homePage.vue')
      },
      // 首页ai开始生成
      {
        path: '/generate',
        name: 'generate',
        component: () => import('../views/AIRelated/startGenerate.vue')
      },
      // 抠图形
      {
        path: '/Upload',
        name: 'Upload',
        redirect: () => {
          return {
            path: '/cutFigure',
            query: {
              currentFunction: 1
            }
          }
        }
      },
      // 裁切
      {
        path: '/cutFigure',
        name: 'cutFigure',
        component: () => import('../views/cropperImage/cropperImagePc.vue')
      },
      // 模糊变清晰(超分)
      {
        path: '/superResolution',
        name: 'superResolution',
        component: () => import('@/views/superResolution/superResolutionPc.vue')
      },
      // gif相关
      {
        path: '/gifCreate',
        name: 'gifCreate',
        component: () => import('@/views/gifCreate/gifCreatePc.vue')
      },
      // 一键抠万物
      {
        path: '/imageMatting',
        name: 'imageMatting',
        component: () => import('@/views/imageMatting/mattingPc')
      },
      // 在线海报
      {
        path: '/posterEdit',
        name: 'posterEdit',
        component: () => import('../views/AIRelated/posterEdit/posterEdit.vue')
      },
      // 图片旋转
      {
        path: '/imgBatchRevolve',
        name: 'imgBatchRevolve',
        component: () => import('../views/imgBatchRevolve/imgBatchRevolvePc.vue')
      },
      // 抠图形
      {
        path: '/seal',
        name: 'seal',
        component: () => import('../views/toolbox/cutSeal.vue')
      }
    ]
  },
  // 首页ai生成历史
  {
    path: '/history',
    name: 'history',
    component: () => import('../views/AIRelated/AIHistory.vue')
  }
]

const routeApp = [
  {
    path: '/',
    name: 'toolbox',
    component: () => import('@/views/layout/layoutMain.vue'),
    redirect: '/toolbox',
    children: [
      {
        path: '/toolbox',
        name: 'toolbox',
        component: () => import('@/views/homePage/homePageApp.vue')
      },
      // 裁切
      {
        path: '/cutFigure',
        name: 'cutFigure',
        component: () => import('../views/cropperImage/cropperImageApp.vue')
      },
      // 模糊变清晰(超分)
      {
        path: '/superResolution',
        name: 'superResolution',
        component: () => import('@/views/superResolution/superResolutionApp.vue')
      },
      // gif相关
      {
        path: '/gifCreate',
        name: 'gifCreate',
        component: () => import('@/views/gifCreate/gifCreateApp.vue')
      },
      // 一键抠万物
      {
        path: '/imageMatting',
        name: 'imageMatting',
        component: () => import('@/views/imageMatting/mattingH5')
      },
      // 在线海报
      {
        path: '/posterEdit',
        name: 'posterEdit',
        component: () => import('../views/AIRelated/posterEdit/posterEdit.vue')
      },
      // 图片旋转
      {
        path: '/imgBatchRevolve',
        name: 'imgBatchRevolve',
        component: () => import('../views/imgBatchRevolve/imgBatchRevolveApp.vue')
      },
      // 抠图形
      {
        path: '/seal',
        name: 'seal',
        component: () => import('../views/toolbox/cutSealApp.vue')
      }
    ]
  }
]
const isPhone = function () {
  const flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  return flag
}

const router = createRouter({
  history: createWebHashHistory(),
  routes: isPhone() ? routeApp : routePC
})

// 路由守卫(版本暂时逻辑)
// router.beforeEach((to, from, next) => {
//   if (isPhone()) {
//     window.open(window.SITE_CONFIG.saasOldBaseUrl, '_self')
//   }
//   next()
// })

export default router
